















































































































































































































































































































































































































import { vxm } from "@/store";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  CaseApiFactory,
  CaseDTO,
  CaseStatus,
  Configuration,
  ContactType,
  AddressDTO,
  ErpcDTO, CaseType, CompanyDTO,
} from "@shared_vue/openapi/caseapi";

import CaseSection from "@/views/widgets/CaseSection.vue"
import { ServiceBusClient, ServiceBusReceiver } from "@azure/service-bus";
import { CButton, CTextarea } from "@coreui/vue";
import {DocumentDTO, OptionDTO} from "@shared_vue/openapi/myvaultapi";
import CalendarModal from "@/views/widgets/modals/CalendarModal.vue";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import ErrorModal from "@/views/widgets/modals/ErrorModal.vue";
import Axios from "axios";
import MiniMap from "@/views/viewcomponents/MiniMap.vue";
import AddAddressModal from "@shared_vue/components/AddAddressModal.vue";


@Component({
  components: { CaseSection, WaitModal, ErrorModal, MiniMap, AddAddressModal, CalendarModal },
})
export default class ExistingCase extends Vue {

  private auth = vxm.auth;
  @Prop(String) readonly caseid: string | undefined;
  private fullCaseInfo: CaseDTO = <CaseDTO>{};
  private caseApi = CaseApiFactory(<Configuration>{ basePath: process.env.VUE_APP_CASE_API_URL }, process.env.VUE_APP_CASE_API_URL)
  private waitABit = false;
  private showCal = false;
  private caseStore = vxm.case;
  private showWait: boolean = false;
  private showError = false;
  private titleString = 'Loading...';
  private serviceBusClient: ServiceBusClient | undefined;
  private receiver: ServiceBusReceiver | undefined;
  private selectedCategory: any = 0;
  private firstLoad: boolean = true;
  private firstLoad2: boolean = true;
  private dirty: boolean = false;
  private showNewDocument1: boolean = false;
  private showNewDocument2: boolean = false;
  private newName : string = ''
  private newCat: any = 0;
  private showSuccessModal: boolean = false;
  private newFile: any ="";
  private newDescription: string = '';
  private documentList : DocumentDTO[] = []
  private addAddressModal: boolean = false;
  private caseCategory: CaseType[] = [];
  selectedPrimaryCategory: string = '';
  selectedSecondaryCategory: number | undefined = 0;
  private companyList: CompanyDTO[] = [];
  private branchList: ErpcDTO[] = [];
  private dontreset: boolean = false;
  private loadingData=true;

  private async fetchRecording(){
    const prefix = this.realCase!.agoraFileName!.split('.')[0];
    await this.caseApi.caseGetRecording(prefix).then((link)=>{
      window.open(link.data, '_blank');
    })
  }
  private async handleDateSelected(arg: any){
    console.log('got date')
    console.log(arg);
    try {
      this.showWait = true;
      //change state to "ONHOLD" and set reminder time
      await this.caseApi.casePauseCase({
        caseId: this.realCase!.id!,
        until: arg
      })
    } catch (e) {
      
    } finally {
      this.showWait = false;
      this.showSuccessModal=true;
    }
  }
  
  get companyOptions(){
    return this.companyList.map(o=>({value:o.id,label:o.name})).sort((a, b) => a.label.localeCompare(b.label));
  }

  get branchOptions(){
    return this.branchList?.filter(b=>b.companyId==this.selectedCompany).map(o=>({value:o.id,label:o.branchName}))??[];
  }
  
  private async getCompanies(){
    let allCompanies = await this.caseApi.caseGetCompanies();
    let allBranches = await this.caseApi.caseGetBranches();
    //this.companyList = [{id:0,name:'PLEASE SELECT'}].concat(allCompanies.data);
    this.branchList = allBranches.data;
    this.loadingData=false;
  }

  private async saveERPC(){
    console.log('saving ERPC...')

    this.realCase!.currentBranchId = this.selectedBranch;
    let result = await this.caseApi.caseUpdateCase(this.realCase!);
    if (result.status!=200){
      console.log('ERROR SAVING ERPC')
    }
    console.log('done!');

  }

  get selectedCompany() :number {
    if (this.realCase?.company){
      return this.realCase?.company.companyId;
    } else {
      return 0;
    }
  }

  set selectedCompany(toWhat: number){
    console.log('set selected company')
    if (this.realCase?.company === undefined) {
      this.realCase!.company = <ErpcDTO> {}
    }
    this.realCase!.company.companyId = toWhat;

  }

  @Watch('selectedCompany')
  onCompany(val: number, oldval: number) {
    console.log('onCompany')
    if (val!=oldval){ //when changing company dropdown, the branch value does not auto-update. idk why
      console.log(this.$refs.branchselect)
    }
  }
  
  get hasRecording() : boolean{
    return !!this.realCase?.agoraFileName;
  }

  @Watch('branchOptions')
  onBranchFilter(val: [], oldval: []) {
    console.log('onbranchfilter')
    if (this.loadingData) {
      return;
    }
    if (val!=oldval){
      // @ts-ignore
      // this.$refs.branchselect.value=val[0].value;

      if (val&&val.length>0) {

        if (!this.firstLoad2) {
          console.log('reset branch')
          // @ts-ignore
          this.selectedBranch = val[0].value;
        } else {
          console.log('do not reset branch')
          this.selectedBranch = this.realCase!.currentBranchId??0;
          this.firstLoad2 = false;
        }
      }
    }
  }

  get selectedBranch() :number {
    if (this.realCase?.company) {
      return this.realCase!.company!.branchId!;
    } else {
      return 0;
    }
  }

  set selectedBranch(toWhat: number){
    console.log(`selectedbranch: ${toWhat}`)
    if (!this.realCase!.company) {
      this.realCase!.company = <ErpcDTO> {}
    }
    this.realCase!.company.branchId = toWhat;
    this.realCase!.currentBranchId = toWhat;
  }
  
  private async saveNewCaseAddress(newad: AddressDTO){
    console.log('savew new address: ' + JSON.stringify(newad));
    this.addAddressModal=false;
    this.showWait=true;
    try {
      let caseObj = await this.caseApi.caseAddAddressToCase(parseInt(this.caseid!), newad);
      this.fullCaseInfo = caseObj.data;
    } catch (err) {
      alert(err)
    }
    finally {
      this.showWait=false;
    }
  }
  
  private get caseComplainantProfile(){
    return this.fullCaseInfo?.caseComplainant;
  }
  
  private goTrackMe(){
    this.$router.push(`/dashboard/trackme/${this.caseid}`)
  }
  
  get isTrackme(): boolean {
    return this.fullCaseInfo.caseTypeId===1; //Trackme
  }

  get uniqueHeaders (): string[] {

    if (this.caseCategory) {
      return Array.from(new Set(this.caseCategory.map(ct => ct.header)));
    } else {
      return [];
    }
  }

  get primaryCategoryOptions() {
    // Convert uniqueHeaders to the format expected by CSelect
    return this.uniqueHeaders.map(header => ({ value: header, label: header }));
  }

  get filteredSecondaryCategories() {
    // Filter the caseTypes based on the selected primary category
    console.log('filter')
    if (this.caseCategory) {
      return this.caseCategory
          .filter(ct => ct.header === this.selectedPrimaryCategory)
          .map(ct => ({value: ct.uid, label: ct.description}));
    } else {
      return [];
    }
  }

  @Watch('realCase')
  @Watch('caseCategory')
  onCaseOrCategoryChanged() {
    if (this.fullCaseInfo &&this.fullCaseInfo.caseTypeId && this.caseCategory && this.caseCategory.length > 0) {
      const actualCase = this.caseCategory.find(c=>c.uid==this.fullCaseInfo?.caseTypeId);
      console.log('log it')
      if (actualCase) {
        this.setSelectedDropdownValues(actualCase);
      }
    }
  }

  get realCase(){
    return this.fullCaseInfo;
  }
  setSelectedDropdownValues(caseType: CaseType) {
    // Set the primary category
    this.selectedPrimaryCategory = caseType.header;

    // Set the secondary category
    this.selectedSecondaryCategory = caseType.uid;
  }
  
  @Watch('selectedPrimaryCategory')
  onPrimaryCategoryChanged(newVal: string, oldVal: string) {
    // Reset the selected secondary category when the primary category changes
    if (!this.firstLoad) {
      this.selectedSecondaryCategory = 0;
    } else {
      this.selectedSecondaryCategory = this.realCase?.caseTypeId??0;
      this.firstLoad=false;
    }
  }

  @Watch('selectedSecondaryCategory')
  onSecondayCategoryChanged(newVal: string, oldVal: string){
    if (newVal && newVal!=='') {
      this.saveNewCaseCategory(parseInt(newVal));
    }
  }
  private saveNewCaseCategory(caseCat: number){
    console.log('savew new cat');
    this.showWait=true;
    try {
      const caseData = this.caseApi.caseSaveCaseCategory(this.realCase!.id!, caseCat )
      this.realCase!.caseTypeId=caseCat;
      this.$emit('updated', caseData);
    } catch (err) {
      console.log(err)
      this.showError=true;
    }
    finally {
      this.showWait=false;
    }
  }
  
  
  
  getFieldValue(key: string): any {
    //@ts-ignore
    return this.fullCaseInfo[key] || '';
  }

  handleFileUpload(filez: any, eo: any){
    console.log('handlefileupload')
    this.newFile=filez[0];
  }
  
  private get caseLocation() {
    return {
      latitude: this.fullCaseInfo?.latitude?parseFloat(this.fullCaseInfo?.latitude):0,
      longitude: this.fullCaseInfo?.longitude?parseFloat(this.fullCaseInfo?.longitude):0,
      infotext:'location'
    }
  }

  private cancel(){
    this.showNewDocument1=false;
    this.showNewDocument2=false;
    this.newDescription='';
    this.newName='';
    this.newCat=null;
    this.showSuccessModal=false;
  }

  public showSuccess(){
    this.showSuccessModal=true;
  }

  private fetchAllDocument(){
    this.showWait=true;
    this.caseApi.caseListCaseFiles(parseInt(this.caseid!)).then(resp => {
      console.log(resp.data);
      //add version stuff here :-O
      this.documentList=resp.data;
    })
        .catch(err => {
          // Handle Error Here
          console.error(err);
        }).finally(()=>{
      this.showWait=false;
    });
  }

  private downloadDocument(id:number){
    this.caseApi.caseGetFileLinkById(parseInt(this.caseid!), id).then((link)=>{
      // let a = document.createElement('a');
      // a.href = link.data;
      // a.target="_blank";
      // a.download = "testfile.txt";//link.data.substr(link.data.lastIndexOf('/') + 1);
      // console.log(a.outerHTML)
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);

      window.open(link.data, '_blank');
    })
  }
  
  private next1(){
    // this.showNewDocument1=false;
    // this.showNewDocument2=true;
    console.log("next1")
    let formData = new FormData();
    formData.append('file', this.newFile);
    formData.append('description', this.newDescription)
    formData.append('case_id', this.caseid!)
    const outerthis = this;
    //move to service
    Axios.post(process.env.VUE_APP_CASE_API_URL + '/Case/NewDocument', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          console.log(response.status);
          if (response.status==200){
            outerthis.cancel();
            outerthis.showSuccess();
            outerthis.fetchAllDocument();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  }
  
  get caseFiles() {
    return [
      {
        id:1,
        name:"file1.png",
        downloadLink:"q23.com"
      },
      {
        id:2,
        name:"file2.mp3",
        downloadLink:"q23.jpg"
      }
    ]
  }

  setFieldValue(key: string, value: any) {
    this.dirty = true;
    //@ts-ignore
    this.fullCaseInfo[key] = value;
  }

  // For properties inside caseData
  getDataValue(key: string): string {
    //@ts-ignore
    return this.fullCaseInfo?.caseData?.[key] || '';
  }

  setDataValue(key: string, value: string) {
    this.dirty=true;
    if (!this.fullCaseInfo?.caseData) {
      this.$set(this.fullCaseInfo, 'caseData', { [key]: value });
    } else {
      this.$set(this.fullCaseInfo.caseData, key, value);
    }
  }
  
  @Watch('auth.idToken')
  onIdToken(val: boolean, oldval: boolean) {
    console.log('onidtoken')
    if (this.waitABit) {
      if (typeof this.caseid === "string") {
        this.fetchData(Number.parseInt(this.caseid))
      }
    }
  }

  get modalTitle(){
    return this.titleString;
  }
  
  get caseStateOptions(){
    return Object.values(CaseStatus).map(item => ({value:item.toString().toUpperCase(), label:item.toString().toUpperCase()}));
  }
  
  get selectedCaseState() : string {
    if (this.fullCaseInfo.id != undefined) {
      return this.fullCaseInfo.status?.toString()??''
    } else {
      return ''
    }
  }

  private reverseMap: Map<string,CaseStatus> = new Map(Object.values(CaseStatus).map(item => [item.toString().toUpperCase(), item]))
  
  set selectedCaseState(toWhat: string){
    // two options:
    // save change on blur?
    console.log('case state change:' + toWhat)
    let enumValue: CaseStatus|undefined = this.reverseMap.get(toWhat);
    this.fullCaseInfo.status = enumValue;
    this.dirty=true;
    if (toWhat==='ONHOLD'){
      this.showCal=true;
    }
  }

  private async updateCase(caseDto: CaseDTO){
    this.titleString='Saving...';
    this.showWait=true;
    try {
      let saveResult = await this.caseApi.caseUpdateCase(caseDto); //won't all these calls follow the same pattern?
      if (saveResult.status!==200){
        throw new Error('bad response')
      }
      this.showWait=false; //might be better as a toast
    } catch (e) {
      this.showWait=false;
      this.showError=true;
    }
  }
  mounted() {
    // this.configureSB(); //todo: future Dirk Problem allow multiple people interacting
    this.showWait = true; 

    if (this.caseid ) { //note we must not just fetch CaseDTO there is more info than that here
      this.fetchData(Number.parseInt(this.caseid))
    }

    // setTimeout(()=>{console.log("sendingme");this.connection?.send("CaseOpenedBy","me");}, 5000);

  }

  private myMessageHandler = async (message: any) => { //type this?
    // your code here
    console.log(`message.body: ${message.body}`);
  };
  private myErrorHandler = async (args: any) => {
    console.log(
      `Error occurred with ${args.entityPath} within ${args.fullyQualifiedNamespace}: `,
      args.error
    );
  };

  private async configureSB() {
    let connectionString = await this.caseApi.caseGetConnectionString();
    console.log('sb connectionstring: ' + connectionString.data);
    this.serviceBusClient = new ServiceBusClient(connectionString.data);
    this.receiver = this.serviceBusClient.createReceiver("cases", <string>this.auth.UserId);

    this.receiver.subscribe({
      processMessage: this.myMessageHandler,
      processError: this.myErrorHandler
    });
  }
  
  private async saveCase() {
    this.showWait=true;
    try {
      await this.caseApi.caseUpdateCase(this.fullCaseInfo);
      if (this.fullCaseInfo.caseData) {
        await this.caseApi.caseSaveCaseData(parseInt(this.caseid!), this.fullCaseInfo.caseData);
      }
    } catch (e){
      this.showWait=false;
      console.log('error')
      this.showError=true;
    } finally {
      this.dirty = false;
      this.showWait = false;
    }
  }
  
  private get CaseNo() {
    if (this.fullCaseInfo.id != undefined) {
      return this.fullCaseInfo.caseNumber
    } else {
      return ''
    }
  }
  
  private navigateToComplainantProfile(){
    this.$router.push(`/dashboard/user/${this.caseComplainantProfile!.guid}`)
  }
  
  private async fetchData(id: number) {
    if (!this.auth.idToken) {
      this.waitABit = true;
      return;
    }
    this.firstLoad = true;
    this.firstLoad2 = true;
    let response = await this.caseApi.caseGetCaseById(id)
    if (response.status == 200) {
      console.log(response.data)
      this.fullCaseInfo = response.data;
    }
    let result2 = await this.caseApi.caseGetCaseCategoryOptions();
    if (result2.status==200){
      // let zeroth = <OptionDTO>{ id: "", text: "Choose" }
      this.caseCategory = result2.data;
    }
    await this.getCompanies();
    this.fetchAllDocument();
  }

  get complainantName() {
    const firstName = this.fullCaseInfo?.caseComplainant?.firstNames || "Name";
    const lastName = this.fullCaseInfo?.caseComplainant?.lastName || "Lastname";
    console.log(this.realCase);
    return firstName + ' ' + lastName;
  }  

  get emergencyContacts() {
    return this.self?.contacts?.filter(contact =>
      (contact.contactType.includes(ContactType.Emergency1) || (contact.contactType.includes(ContactType.Emergency2)))
    )
  }
  
  get homeAddressString() {
    return this.getCompactAddress(this.homeAddress);
  }

  get caseAddressString() {
    return this.fullCaseInfo?.address??"";
  }

  get workAddressString() {
    return this.getCompactAddress(this.workAddress);
  }

  private getCompactAddress(address: AddressDTO | undefined): string {
    if (address) {
      return `${address.streetName}, ${address.suburb}, ${address.city}, ${address.postalCode}, ${address.country}`
    }
    return "";
  }

  get self() {
    return this.fullCaseInfo?.caseComplainant;
  }

  get selfContact() {
    return this.self?.contacts?.find(contact =>
      contact.contactType.includes(ContactType.Self)
    )
  }

  dial(number: string) {
    alert(number);
  }

  get homeAddress() {
    return this.selfContact?.addresses?.find(add => add.addressTypeId === 'Primary');
  }

  get workAddress() {
    return this.selfContact?.addresses?.find(add => add.addressTypeId === 'Work');
  }
}
